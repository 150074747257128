import { useContext } from "react";
import { LanguageContext } from "../context/contextLang";

const useLanguage = () => {
  const context = useContext(LanguageContext);

  if (context === null) {
    throw new Error(
      "useLanguage must be used within a LanguageContextProvider"
    );
  }

  return context;
};

export default useLanguage;
