import React, { FC } from "react";
import WorkItem from "../WorkItem/WorkItem";
import { IWork } from "../../../interface/works.interface";
import { useAOS } from "../../../hooks/useAOS";

type SingleWorkProps = {
  project: IWork;
};

const SingleWork: FC<SingleWorkProps> = ({ project }) => {
  useAOS();
  return (
    <div className="works__columns" data-aos="flip-left" data-aos-offset="100">
      <a
        href={project.url}
        target="_blank"
        rel="noopener noreferrer"
        className="works__item"
      >
        <div className="item__img">
          <img src={`/images/${project.image}`} alt={project.title} />
        </div>
        <WorkItem project={project} />
      </a>
    </div>
  );
};

export default SingleWork;
