import React from "react";
import LanguageContextProvider from "./context/contextLang";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import "./index.css";
import App from "./components/App/App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <SkeletonTheme baseColor="#202020" highlightColor="#444">
    <BrowserRouter>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </BrowserRouter>
  </SkeletonTheme>
);
