import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type WorkSkeletonProps = {
  cardsLength: number;
};

const WorkSkeleton: FC<WorkSkeletonProps> = ({ cardsLength }) => {
  return (
    <>
      {Array(cardsLength)
        .fill(0)
        .map((_, i) => {
          return (
            <div className="works__columns" key={i}>
              <div className="skeleton-photo">
                <Skeleton width="100%" height={300} />
              </div>
              <div className="skeleton-text">
                <Skeleton width="35%" height={10} />
              </div>
              <div className="skeleton-text">
                <Skeleton width="70%" height={10} />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default WorkSkeleton;
