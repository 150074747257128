import React, { FC } from "react";
import { ITeam } from "../../../interface/team.interface";
import { useAOS } from "../../../hooks/useAOS";

type SinglePersonProps = {
  person: ITeam;
  duration: number;
};

const SinglePerson: FC<SinglePersonProps> = ({ person, duration }) => {
  useAOS();
  const { fullName, profession, description, linkedinURL, facebookURL, image } =
    person;
  return (
    <div className="team__items" data-aos="fade-left" data-aos-duration={duration} data-aos-offset="100">
      <div className="team__item">
        <div className="item__img">
          <img src={`/images/${image}`} alt={fullName} />
        </div>
        <div className="item__name">{fullName}</div>
        <div className="item__profession">{profession}</div>
        <div className="item__social">
          <a href={facebookURL} className="facebook" aria-label="Facebook link">
            <i className="icon-facebook"></i>
          </a>
          <a href={linkedinURL} className="linkedin" aria-label="LinkedIn link">
            <i className="icon-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SinglePerson;
