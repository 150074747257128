import React, { createContext, useEffect, useState } from "react";

export type LanguageContextType = "en" | "am" | "ru";

interface LanguageContextData {
  language: LanguageContextType;
  switchLanguage: (updateLang: LanguageContextType) => void;
}

export const LanguageContext = createContext<LanguageContextData | null>(null);

interface LanguageContextProviderProps {
  children: React.ReactNode;
}

const LanguageContextProvider: React.FC<LanguageContextProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState<LanguageContextType>(
    (localStorage.getItem("lang") as LanguageContextType) ?? "en"
  );
  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setLanguage(localStorage.getItem("lang") as LanguageContextType);
    }
    switch (language) {
      case "en":
        document.body.style.fontFamily = "var(--font-family-default)";
        break;
      case "am":
        document.body.style.fontFamily = "var(--font-family-armenian)";
        break;
      default:
        document.body.style.fontFamily = "var(--font-family-default)";
    }
  }, [language]);

  const switchLanguage = (updateLang: LanguageContextType) => {
    setLanguage(updateLang);
    localStorage.setItem("lang", updateLang);
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
