import React, { FC } from "react";
import { IStaticData } from "../../../interface/works.interface";
import WorkItem from "../WorkItem/WorkItem";
import { useAOS } from "../../../hooks/useAOS";

type YourSiteProps = {
  staticData: IStaticData;
};

const YourSite: FC<YourSiteProps> = ({ staticData }) => {
  useAOS();
  return (
    <div className="works__columns" data-aos="flip-left">
      <div className="works__item">
              <div className="item__img your-site">
        <img src="/img/your-site.jpg" alt="your site"/>
          <span className="two">{staticData.textOnImgSub}</span>
        </div>
        <WorkItem project={staticData} />
      </div>
    </div>
  );
};

export default YourSite;
