import "./SuccessMessage.css";
import useLanguage from "../../hooks/useLanguageContext";
import database from "./database.json";
import { IStaticData } from "../../interface/successMessage.interface";

const SuccessMessage = () => {
  const { language } = useLanguage();
  const data: IStaticData = database[language];
  return (
    <div className="card">
      <div
        style={{
          borderRadius: 200,
          height: 200,
          width: 200,
          background: "#F8FAF5",
          margin: "0 auto",
        }}
      >
        <i className="checkmark">✓</i>
      </div>
      <strong>{data.success}</strong>
      <p>{data.successText}</p>
    </div>
  );
};

export default SuccessMessage;
