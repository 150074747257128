import React from "react";
import useLanguage from "../../hooks/useLanguageContext";
import database from "./database.json";
import "./MainSection.css";
import { IStaticData } from "../../interface/mainSection.interface";
import { useAOS } from "../../hooks/useAOS";

const MainSection = () => {
  useAOS();
  const { language } = useLanguage();
  const staticData: IStaticData = database[language];

  return (
    <section className="main">
      <div className="container">
              <div className="main__wrapper" data-aos="fade-in"
                  data-aos-duration="1000">
          <h1
            className="main__title"
          >
            {staticData.title} {/*<b>{staticData.titleBold}</b>*/}
          </h1>
          <p className="main__text" >
            {staticData.mainText}
                  </p>
                      <a
                          href="#assessment"
                          className="btn"
                         
                      >
                          {staticData.buttonText}
                      </a>
          
        </div>
      </div>
    </section>
  );
};

export default MainSection;
