import { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "./Header.css";
import { useAOS } from "../../hooks/useAOS";

const Header = () => {
  useAOS();
  const [toggleMenu, setToggleMenu] = useState(false);

  const closeMenu = (prev: boolean) => {
    setToggleMenu(!prev);
    };

    const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__wrapper">
          <Link
                      to="/"
                      className="header__logo"
                      aria-label="Logo"
                      onClick={scrollUp}
          >
            <i className="icon-logo" />
          </Link>
          <Navbar closeMenu={closeMenu} toggleMenu={toggleMenu} />
          <div
            className={`header__burger ${toggleMenu ? "active" : ""} `}
                      onClick={() => closeMenu(toggleMenu)}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
