import { FC, useEffect, useRef, useState } from "react";
import useLanguage from "../../hooks/useLanguageContext";
import menuList from "./menu.json";
import { LanguageContextType } from "../../context/contextLang";
import { useAOS } from "../../hooks/useAOS";
import usaFlag from "./img/usa.png";
import armFlag from "./img/arm.png";
import russiaFlag from "./img/russia.png";

type NavbarProps = {
  closeMenu: (toggleMenu: boolean) => void;
  toggleMenu: boolean;
};

const Navbar: FC<NavbarProps> = ({ closeMenu, toggleMenu }) => {
  useAOS();
  const { language, switchLanguage } = useLanguage();
  const flag: string =
    language === "en" ? usaFlag : language === "am" ? armFlag : russiaFlag;
  const [currentLanguage, setCurrentLanguage] = useState(flag);
  const [openLanguageList, setOpenLanguageList] = useState(false);
  const dropdownRef = useRef<HTMLLIElement | null>(null);

  const changeLanguage = (
    changeFlag: string,
    language: LanguageContextType
  ) => {
    setCurrentLanguage(changeFlag);
    switchLanguage(language);
    setOpenLanguageList(false)
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenLanguageList(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dropdownRef]);



  return (
    <nav className={`header__navigation ${toggleMenu ? "active" : ""} `}>
      <ul className="nav__list" data-aos="fade-left">
        {menuList &&
          menuList[language].menu.map((item) => (
              <li key={item.id}>
                  <a href={`#${item.id}`} onClick={() => closeMenu(toggleMenu)}>
                {item.name}
              </a>
            </li>
          ))}
        <li
          ref={dropdownRef}
          className={openLanguageList ? "menu-lang active" : "menu-lang"}
        >
          <button
                      className="lang-button"
                      onClick={() => setOpenLanguageList((prev) => !prev)}
                      style={{ backgroundImage: `url(${currentLanguage})` }}
                      title={menuList[language].langTitle}
          />
          {openLanguageList && (
            <ul className="lang-menu__list">
              <li
                className={`lang-menu__item ${
                  language === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage(usaFlag, "en")}
                title="EN"
              >
                <img src={usaFlag} alt="flag usa" />
              </li>
              <li
                className={`lang-menu__item ${
                  language === "am" ? "active" : ""
                }`}
                onClick={() => changeLanguage(armFlag, "am")}
                title="AM"
              >
                <img src={armFlag} alt="flag armenia" />
              </li>
              <li
                className={`lang-menu__item ${
                  language === "ru" ? "active" : ""
                }`}
                onClick={() => changeLanguage(russiaFlag, "ru")}
                title="RU"
              >
                <img src={russiaFlag} alt="flag russia" />
              </li>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
