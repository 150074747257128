import React, { useEffect } from "react";
import useLanguage from "../../hooks/useLanguageContext";
import database from "./database.json";
import "./NotFoundPage.css";
import { IStaticData } from "../../interface/notfound.interface";

const NotFoundPage = () => {
  const { language } = useLanguage();
  const staticData: IStaticData = database[language];

  useEffect(() => {
    document.title = "Error 404";

    return () => {
      document.title = "TechMind";
    };
  }, []);

  return (
    <section className="not-found">
      <div className="container">
        <div className="not-found__wrapper">
          <p>404</p>
          <h1>{staticData.title}</h1>
          <h2>{staticData.subtitle}</h2>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
