import React, { useEffect, useState } from "react";
import { getApiResource } from "../../utils/network";
import { API_SERVICES } from "../../config";
import useLanguage from "../../hooks/useLanguageContext";
import SingleServices from "./SingleServices/SingleServices";
import database from "./database.json";
import useLoading from "../../hooks/useLoading";
import ServicesSkeleton from "./ServicesSkeleton/ServicesSkeleton";
import { IService, IStaticData } from "../../interface/services.interface";
import "react-loading-skeleton/dist/skeleton.css";
import "./Services.css";
import { useAOS } from "../../hooks/useAOS";

const Services = () => {
  useAOS();
  const { language } = useLanguage();
  const staticData: IStaticData = database[language];
  const [services, setServices] = useState<IService[]>([]);
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: IService[] | null = await getApiResource<IService[]>(
          API_SERVICES + language
        );
        if (data !== null) {
          setServices(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [language]);

  return (
    <section className="services" id="2">
      <div className="container">
        <div className="services__title title" data-aos="fade-in">{staticData.title}</div>
        <div className="services__items">
          {isLoading ? (
            <ServicesSkeleton cardsLength={2} />
          ) : (
            services.map((service: IService) => (
              <SingleServices key={service.id} service={service} />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Services;
