import React from "react";
import useLanguage from "../../hooks/useLanguageContext";
import "./InputRequiredText.css";
const InputRequiredText = () => {
  const { language } = useLanguage();

  const errorMessage =
    language === "en"
      ? "This field is required !"
      : language === "am"
      ? "Լրացրեք դաշտը !"
      : "Заполните поле !";

  return <div className="error-message">{errorMessage}</div>;
};

export default InputRequiredText;
