import React from "react"
import Lottie from "lottie-react";
import AnimationMind from "./mind.json"

const LottieLoader = () => {
    return (
        <div className="loader">
            <Lottie animationData={AnimationMind} style={{ width: 350, marginLeft: "auto", marginRight: "auto", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 100 }} />
        </div>
    )
}

export default LottieLoader