import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type ServicesSkeletonProps = {
  cardsLength: number;
};

const ServicesSkeleton: FC<ServicesSkeletonProps> = ({ cardsLength }) => {
  return (
    <>
      {Array(cardsLength)
        .fill(0)
        .map((_, i) => {
          return (
            <div className="services__columns" key={i}>
              <div className="skeleton-photo">
                <Skeleton width="100%" height={400} />
                <div className="skeleton-text absolute">
                  <Skeleton
                    width="70%"
                    height={10}
                    style={{ backgroundColor: "#333" }}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ServicesSkeleton;
