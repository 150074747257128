import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type TeamSkeletonProps = {
  cardsLength: number;
};

const TeamSkeleton: FC<TeamSkeletonProps> = ({ cardsLength }) => {
  return (
    <>
      {Array(cardsLength)
        .fill(0)
        .map((_, i) => {
          return (
            <div className="team__items" key={i}>
              <div className="skeleton-photo">
                <Skeleton width="100%" height={300} />
              </div>
              <div className="skeleton-text">
                <Skeleton width="70%" height={13} />
                <Skeleton width="80%" height={13} style={{ marginTop: 5 }} />
              </div>
              <div className="skeleton-text">
                <Skeleton width="90%" height={10} count={5} />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default TeamSkeleton;
