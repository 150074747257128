import { FC, useState } from "react";
import classNames from "classnames";
import InputRequiredText from "../InputRequiredText/InputRequiredText";

type FloatingLabelInputProps = {
  label: string;
  type?: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  name: string;
  maxLength?: number;
  ariaLabel: string;
};

const FloatingLabelInput: FC<FloatingLabelInputProps> = ({
  label,
  type,
  value,
  onChange,
  name,
  maxLength,
  ariaLabel,
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleFocus = () => {
    setFocused(true);
    if (!value) {
      setHasError(false);
    }
  };

  const handleBlur = () => {
    if (!value) {
      setFocused(false);
      setHasError(true);
    }
  };

  const containerClasses = classNames("floating-label-input", {
    focused: focused,
  });

  const InputElement = name === "message" ? "textarea" : "input";

  return (
    <div className={containerClasses}>
      <div data-aos="fade-right">
        <label className="label">{label}</label>
        <InputElement
          name={name}
          onChange={onChange}
          value={value}
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          required
          className="form__input"
          maxLength={maxLength}
          aria-label={ariaLabel}
        />
      </div>
      {hasError && <InputRequiredText />}
    </div>
  );
};

export default FloatingLabelInput;
