import axios, { AxiosResponse } from "axios";

export const getApiResource = async <T>(url: string): Promise<T | null> => {
  try {
    const response: AxiosResponse<T> = await axios.get(url);
    if (!response.status) {
      console.error("Could not fetch", response.status);
      return null;
    }

    return response.data;
  } catch (error) {
    const castedError = error as Error;
    // Explicitly specify the error type as Error
    console.error("Could not fetch", castedError.message);
    return null;
  }
};

export const getApiImage = async (url: string): Promise<string | null> => {
  try {
    const response: AxiosResponse<Blob> = await axios.get(url, {
      responseType: "blob",
    });
    const imageUrl = URL.createObjectURL(response.data);
    return imageUrl;
  } catch (error) {
    const castedError = error as Error;
    // Explicitly specify the error type as Error
    console.error("Error fetching image:", castedError.message);
    return null;
  }
};
