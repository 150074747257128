import React, { useState, useEffect } from "react";
import { API_TEAM } from "../../config";
import useLanguage from "../../hooks/useLanguageContext";
import useLoading from "../../hooks/useLoading";
import { getApiResource } from "../../utils/network";
import database from "./database.json";
import SinglePerson from "./SinglePerson/SinglePerson";
import TeamSkeleton from "./TeamSkeleton/TeamSkeleton";
import { IStaticData, ITeam } from "./../../interface/team.interface";
import "./Team.css";
import { useAOS } from "../../hooks/useAOS";

const Team = () => {
  useAOS();
  const [team, setTeam] = useState<ITeam[]>([]);
  const { language } = useLanguage();
  const staticData: IStaticData = database[language];
  const { isLoading, setIsLoading } = useLoading();
  let duration = 500;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: ITeam[] | null = await getApiResource<ITeam[]>(
          API_TEAM + language
        );
        if (data !== null) {
          setTeam(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [language]);

  return (
    <section className="team" id="3">
      <div className="container">
        <div className="team__title title" data-aos="fade-in">
          {staticData.title}
        </div>
        <div className="team__columns">
          {isLoading ? (
            <TeamSkeleton cardsLength={5} />
          ) : (
            team.map((person: ITeam) => {
              duration = duration + 100;
              return (
                <SinglePerson
                  person={person}
                  key={person.id}
                  duration={duration}
                />
              );
            })
          )}
        </div>
      </div>
    </section>
  );
};

export default Team;
