import { useAOS } from "../../../hooks/useAOS";

const Contacts = () => {
  useAOS();
  return (
      <div className="footer__contacts" data-aos="fade-left">
          <a href="tel:+19513316693" className="phone phone1">
              +1 (951) 331-6693
          </a>
      <a href="tel:+37499711144" className="phone">
        +374 99 71 11 44
      </a>
      <a href="mailto:techmind.am@gmail.com" className="mail">
        techmind.am@gmail.com
      </a>
      <div className="footer__social">
        <a
          href="https://wa.me/37499711144"
          target="_blank"
          rel="noopener noreferrer"
          title="Whatsapp"
        >
          <i className="icon-whatsap"></i>
        </a>
        <a
          href="https://t.me/techmind5"
          target="_blank"
          rel="noopener noreferrer"
          title="Telegram"
        >
          <i className="icon-telegram"></i>
        </a>
      </div>
    </div>
  );
};

export default Contacts;
