import { useEffect, useState } from "react";
import useLanguage from "../../hooks/useLanguageContext";
import database from "./database.json";
import SingleWork from "./SingleWork/SingleWork";
import { getApiResource } from "../../utils/network";
import { API_PROJECTS } from "../../config";
import YourSite from "./YourSite/YourSite";
import WorkSkeleton from "./WorkSkeleton/WorkSkeleton";
import { IStaticData, IWork } from "../../interface/works.interface";
import "./Works.css";
import { useAOS } from "../../hooks/useAOS";

const Works = () => {
  useAOS();
  const { language } = useLanguage();
  const staticData: IStaticData = database[language];
  const [projects, setProjects] = useState<IWork[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: IWork[] | null = await getApiResource<IWork[]>(
          API_PROJECTS + language
        );
        if (data !== null) {
          setProjects(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [language]);

  return (
    <section className="works" id="1">
      <div className="container">
        <div className="works__title title" data-aos="fade-in">{staticData.sectionName}</div>
        <div className={`works__items ${isLoading ? "skl" : ""}`}>
          {isLoading ? (
            <WorkSkeleton cardsLength={3} />
          ) : (
            <>
              {projects.map((project: IWork) => (
                <SingleWork key={project.id} project={project}  />
              ))}
              {!isLoading && <YourSite staticData={staticData} />}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Works;
