import React, { useState } from "react";
import useApi from "../../../hooks/useApi";
import { API_CALLBACK } from "../../../config";
import { initialFormData } from "./formConstants";
import FloatingLabelInput from "../../../UI/FloatingLabelInput/FloatingLabelInput";
import SuccessMessage from "../../SuccessMessage/SuccessMessage";
import useLanguage from "../../../hooks/useLanguageContext";
import LottieLoader from "../../../UI/LottieLoader/LottieLoader";
import {
  IFormData,
  IStaticData,
} from "./../../../interface/callback.interface";
import { useAOS } from "../../../hooks/useAOS";

type CallBackFormProps = {
  staticData: IStaticData;
};

const CallBackForm: React.FC<CallBackFormProps> = ({ staticData }) => {
  useAOS();
  //const [captchaImage, setCaptchaImage] = useState(null);
  //const [captchaVerified, setCaptchaVerified] = useState(false);
  const [values, setValues] = useState<IFormData>(initialFormData);
  const { isLoading, errorText, sendRequest } = useApi();
  const [isSent, setIsSent] = useState<boolean>(false);
  const { language } = useLanguage();

  //useEffect(() => {
  //    axios.get(API_GENERATE_CAPTCHA, {
  //        responseType: "blob"
  //    })
  //        .then(response => {
  //            const imageUrl = URL.createObjectURL(response.data);
  //            setCaptchaImage(imageUrl);
  //        })
  //        .catch(error => {
  //            console.error("Error fetching image:", error);
  //        });

  //    return () => {
  //        URL.revokeObjectURL(captchaImage);
  //    };
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, []);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //if (!captchaVerified) {
    //    console.error("Captcha not verified");
    //    return;
    //}

    const url = API_CALLBACK + language;
    const data = {
      name: values.name,
      email: values.email,
      description: values.description,
      /* captcha: values.captcha*/
    };

    sendRequest(url, data).then((response) => {
      console.log(response);
      setIsSent(true);
      setTimeout(() => {
        setIsSent(false);
      }, 2000);
      setValues(initialFormData);
    });
  };

  //const verifyCaptcha = (e) => {
  //    e.preventDefault();
  //    axios.post(`${API_VERIFY_CAPTCHA}${values.captcha}`)
  //        .then(response => {
  //            debugger
  //            if (response.data.success) {
  //                setCaptchaVerified(true);
  //                sendEmail();
  //            }
  //        })
  //        .catch(error => {
  //            console.error("Error verifying captcha:", error);
  //        });
  //};
  return (
    <>
      {isLoading && <LottieLoader />}
      {errorText && <p className="error-text">{errorText}</p>}
      <form className="callback__form" onSubmit={sendEmail}>
        <FloatingLabelInput
          name="name"
          label={staticData.inputName}
          type="text"
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          ariaLabel="Fullname"
        />
        <FloatingLabelInput
          type="email"
          name="email"
          label={staticData.inputEmail}
          value={values.email}
          onChange={(e) => setValues({ ...values, email: e.target.value })}
          ariaLabel="Email"
        />
        <FloatingLabelInput
          name="message"
          label={staticData.message}
          value={values.description}
          onChange={(e) =>
            setValues({ ...values, description: e.target.value })
          }
          ariaLabel="Message"
        />
        {/*{captchaImage && (*/}
        {/*    <img*/}
        {/*        src={captchaImage}*/}
        {/*        alt="Captcha"*/}
        {/*        className="captcha"*/}
        {/*    />*/}
        {/*)}*/}
        {/*<FloatingLabelInput*/}
        {/*    name="captcha"*/}
        {/*    value={values.captcha}*/}
        {/*    type="text"*/}
        {/*    onChange={(e) => setValues({ ...values, captcha: e.target.value })}*/}
        {/*    maxLength={4}*/}
        {/*/>*/}
        <button className="btn" data-aos="fade-up">{staticData.buttonText}</button>
      </form>
      {isSent && <SuccessMessage />}
    </>
  );
};

export default CallBackForm;
