import "./Footer.css";
import Contacts from "./Contacts/Contacts";
import { useAOS } from "../../hooks/useAOS";

const Footer = () => {
  useAOS();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__logo" data-aos="fade-right">
            <i className="icon-logo"></i>
            <span>© TechMind 2022–{new Date().getFullYear()}.</span>
          </div>
          <Contacts />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
