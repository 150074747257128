import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IMG_JS from "./img/js.png";
import IMG_REACT from "./img/react-icon.png";
import IMG_HTML5 from "./img/html5.png";
import IMG_CSS3 from "./img/css3.png";
import IMG_CS from "./img/cs.png";
import IMG_ASPNET from "./img/aspnet.png";
import IMG_JQUERY from "./img/jquery.png";
import IMG_SQL from "./img/sql.png";
import IMG_RESTAPI from "./img/restapi.png";
import "./WorkWith.css";
import { useAOS } from "../../hooks/useAOS";

const WorkWith = () => {
  useAOS();
  return (
    <section className="workWith">
      <Slider
        infinite={true}
        speed={500}
        autoplay={true}
        autoplaySpeed={2000}
        slidesToShow={6}
        slidesToScroll={1}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="100">
          <img src={IMG_HTML5} alt="HTML5" />
        </div>
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="200">
          <img src={IMG_CSS3} alt="CSS3" />
        </div>
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="300">
          <img src={IMG_JS} alt="JAVASCRIPT" />
        </div>
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="400">
          <img src={IMG_REACT} alt="REACT" />
        </div>
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="500">
          <img src={IMG_JQUERY} alt="Jquery" />
        </div>
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="600">
          <img src={IMG_RESTAPI} alt="REST API" />
        </div>
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="700">
          <img src={IMG_CS} alt="C#" />
        </div>
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="800">
          <img src={IMG_ASPNET} alt="ASP NET CORE" />
        </div>
        <div className="workWith-img" data-aos="fade-in" data-aos-delay="900">
          <img src={IMG_SQL} alt="SQL" />
        </div>
      </Slider>
    </section>
  );
};

export default WorkWith;
