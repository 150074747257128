import { FC } from "react";
import { IStaticData, IWork } from "../../../interface/works.interface";

type WorkItemProps = {
  project: IWork | IStaticData;
};
const WorkItem: FC<WorkItemProps> = ({ project }) => {
  return (
    <div className="item__info" >
      <div className="item__info-top">
        <h2>{project.title}</h2>
      </div>
      <div className="flex">
        <p>{project.description}</p>
        <i className="icon-blank" />
      </div>
    </div>
  );
};

export default WorkItem;
