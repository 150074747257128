import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

import "./BackToTop.css";

const BackToTop: React.FC = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  const listenToScroll = () => {
    let heightToHidden = 600;

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setBackToTopButton(true);
    } else {
      setBackToTopButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);

    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return backToTopButton ? (
    <div onClick={scrollUp} className="backToTop__container">
      <FaArrowUp className="backToTop" />
    </div>
  ) : null;
};

export default BackToTop;
