import React from "react"
import MainSection from "../../components/MainSection/MainSection";
import Services from "../../components/Services/Services";
import Team from "../../components/Team/Team";
import Works from "../../components/Works/Works";
import CallBack from "../../components/CallBack/CallBack";
import WorkWith from "../../components/WorkWith/WorkWith";
import BlurEffect from "../../UI/BlurEffect/BlurEffect";


const HomePage = () => {
    return (
        <>
            <BlurEffect />
            <MainSection />
            <div style={{ position: "relative" }}>
                <Works />
                <Services />
            </div>
            <Team />
            <WorkWith />
            <CallBack />
        </>
    )
}

export default HomePage;