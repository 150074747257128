import React, { FC } from "react";
import { IService } from "../../../interface/services.interface";
import { useAOS } from "../../../hooks/useAOS";

type SingleServicesProps = {
  service: IService;
};

const SingleServices: FC<SingleServicesProps> = ({ service }) => {
  useAOS();
  return (
    <div className="services__columns" data-aos="flip-right">
      <div className="item__img">
        <img src={`/images/${service.image}`} alt={service.title} />
        <h3>{service.title}</h3>
      </div>
    </div>
  );
};

export default SingleServices;
