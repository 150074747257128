import { useState } from "react";
import axios from "axios";

function useApi() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");

  const sendRequest = <T,>(url: string, data: T): Promise<T> => {
    setIsLoading(true);
    setErrorText("");

    return axios
      .post(url, data)
      .then((response) => {
        setIsLoading(false);
        return response.data as T;
      })
      .catch((error) => {
        console.error("Ошибка:", error);
        setIsLoading(false);
        setErrorText("Произошла ошибка при выполнении запроса.");
        throw error;
      });
  };

  return { isLoading, errorText, sendRequest };
}

export default useApi;
