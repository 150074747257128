import React from "react";
import CallBackForm from "./CallBackForm/CallBackForm";
import callBackPhoto from "./img/call-back.png";
import "./CallBack.css";
import useLanguage from "../../hooks/useLanguageContext";
import database from "./database.json";
import { IStaticData } from "../../interface/callback.interface";
import { useAOS } from "../../hooks/useAOS";

const CallBack = () => {
  useAOS();
  const { language } = useLanguage();
  const staticData: IStaticData = database[language];

  return (
    <section className="callback" id="assessment">
      <div className="container">
        <div className="callback__title title" data-aos="fade-in">
          {staticData.title}
        </div>
        <div className="callback__subtitle" data-aos="fade-in">
          {staticData.subTitle}
        </div>
        <div className="callback__wrapper">
          <CallBackForm staticData={staticData} />
          <div className="callback__photo" data-aos="fade-up">
            <img src={callBackPhoto} alt="Person" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallBack;
